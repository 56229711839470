
const IconForwardNav = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '25'}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1.875 12.875H22.875' stroke={fillColor || '#510C76'} strokeMiterlimit='10' />
      <path d='M16.125 6.125L22.875 12.875L16.125 19.625' stroke={fillColor || '#510C76'} strokeMiterlimit='10' />
    </svg>
  )
}
export default IconForwardNav